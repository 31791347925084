import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; // For redirection
import AuthLayout from "../layouts/auth";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { createUser } from "../services/user"; // API call
import { gtag } from "../utils/gtag"; // Import gtag function

const Signup = () => {
    const { t } = useTranslation(); // Hook to handle translations
    const navigate = useNavigate(); // For redirection
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPassword, setIsPassword] = useState(true);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [contact, setContact] = useState("");
    const navigationTimer = useRef(null);

    const handleTogglePassword = (e) => {
        e.preventDefault();
        setIsPassword(!isPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        const userSubscriptionStr = localStorage.getItem('userSubscription');
        const userSubscription = userSubscriptionStr ? JSON.parse(userSubscriptionStr) : null;
        
        // Validate password confirmation
        if (password !== confirmPassword) {
            setError(t('signup.passwordMismatch'));
            setLoading(false);
            return;
        }

        // Call the API
        const userData = { 
            email, 
            password,
            username,
            contact,
            plan: userSubscription?.plan || 'free'
        };
        try {
            const response = await createUser(userData);
            if (response.statusCode === 200) {
                // Track Google Ads conversion
                gtag('event', 'conversion', {
                    'send_to': 'AW-431449345/UUPJCICitLUaEIHK3c0B'
                });
                
                // Clear any existing timer
                if (navigationTimer.current) {
                    clearTimeout(navigationTimer.current);
                }
                
                // Set new timer for navigation
                navigationTimer.current = setTimeout(() => {
                    navigate('/signin');
                }, 500);
            } else {
                setError(t('signup.error'));
            }
        } catch (err) {
            setError(err.response?.data?.message || t('signup.error'));
        } finally {
            setLoading(false);
        }
    };

    // Cleanup timer on component unmount
    useEffect(() => {
        return () => {
            if (navigationTimer.current) {
                clearTimeout(navigationTimer.current);
            }
        };
    }, []);

    return (
        <>
            <AuthLayout>
                <div className={`container mx-auto px-5`}>
                    <div className={`border border-white rounded-[2rem] bg-[#FFFFFF50] px-[2rem] md:px-[3.5rem] lg:px-[5rem] py-[1.25rem] md:py-[2rem] lg:py-[3rem] w-full max-w-[300px] md:max-w-[500px] lg:max-w-[700px] mx-auto`}>
                        <form onSubmit={handleSubmit}>
                            <p className={`text-white font-bold text-lg md:text-xl lg:text-3xl mb-6 md:mb-5 lg:mb-8`}>
                                {t('signup.title')}
                            </p>

                            {error && <p className="text-red-500">{error}</p>}

                            <label className={`text-base md:text-lg text-white font-bold block`}>
                                {t('signup.emailLabel')}
                            </label>
                            <input
                                autoComplete="off"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`border-b-[3px] border-white w-full bg-transparent text-white text-sm md:text-base mb-4 md:mb-6 focus:outline-none`}
                            />

                            <label className={`text-base md:text-lg text-white font-bold block`}>
                                {t('signup.usernameLabel')}
                            </label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className={`border-b-[3px] border-white w-full bg-transparent text-white text-sm md:text-base mb-4 md:mb-6 focus:outline-none`}
                            />

                            <label className={`text-base md:text-lg text-white font-bold block`}>
                                {t('signup.passwordLabel')}
                            </label>
                            <div className={`relative`}>
                                <input
                                    autoComplete="new-password"
                                    type={isPassword ? "password" : "text"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={`border-b-[3px] border-white w-full bg-transparent text-white text-sm md:text-base mb-4 md:mb-6 focus:outline-none`}
                                />
                                <button className={`text-ta--grey text-xs absolute top-0 end-1`} onClick={handleTogglePassword}>
                                    <VisibilityOffOutlinedIcon />
                                </button>
                            </div>

                            <label className={`text-base md:text-lg text-white font-bold block`}>
                                {t('signup.confirmPasswordLabel')}
                            </label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className={`border-b-[3px] border-white w-full bg-transparent text-white text-sm md:text-base mb-4 md:mb-6 focus:outline-none`}
                            />

                            <button
                                type="submit"
                                disabled={loading}
                                className={`block mx-auto px-[3rem] md:px-[5rem] lg:px-[8rem] py-2 rounded-lg bg-ta--yellow border border-ta--yellow text-white text-sm md:text-base font-bold transition-all hover:bg-transparent hover:text-ta--yellow`}
                            >
                                {loading ? t('signup.loading') : t('signup.registerButton')}
                            </button>
                        </form>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default Signup;